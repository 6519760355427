<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>Histórico de Rentabilidad</b-card-title>
      <!-- datepicker -->
<!--      <div class="d-flex align-items-center">
        <feather-icon
          icon="CalendarIcon"
          size="16"
        />
        <flat-pickr
          v-model="rangePicker"
          :config="{ mode: 'range'}"
          class="form-control flat-picker bg-transparent border-0 shadow-none"
          placeholder="YYYY-MM-DD"
        />
      </div>-->
      <!-- datepicker -->
    </b-card-header>

    <!-- chart -->
    <b-card-body>
      <b-row class="">
        <b-col cols="1" class="p-1">
          <b-button class="btn p-2 btn-icon" variant="outline-secondary" >
            <Icon icon="ph:building-office" class="graphIcon" />
            Activos
          </b-button>
        </b-col>
        <b-col cols="1"  class="p-1">
          <b-button class="btn p-2 btn-icon" variant="outline-secondary" >
            <Icon icon="iconoir:coins" class="graphIcon" />
            Pasivos
          </b-button>
        </b-col>
      </b-row>
      <bar-chart
          :chart-data="dataCollection"
                 :options="chartOptions">
      </bar-chart>

    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BCardTitle, BButton, BCol, BRow
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import { Bar } from 'vue-chartjs'
import { Icon } from '@iconify/vue2'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
    BButton,
    BCol,
    BRow,
    Icon,
    BarChart: {
      extends: Bar,
      props: {
        chartData: {
          type: Object,
          required: true
        },
        options: {
          type: Object,
          default: () => ({})
        }
      },
      mounted() {
        this.renderChart(this.chartData, this.options)
      }
    }
  },
      data() {
        return {
          dataCollection: {
            labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
            datasets: [
              {
                label: 'Dataset 1',
                data: [12, 19, 3, 5, 2, 3, 7],
                backgroundColor: 'rgba(255, 99, 132, 0.6)',
              },
              {
                label: 'Dataset 2',
                data: [200, 180, 250, 300, 220, 270, 260],
                backgroundColor: 'rgba(54, 162, 235, 0.6)',
              },
              {
                label: 'Dataset 3',
                data: [40, 30, 60, 45, 55, 50, 65],
                backgroundColor: 'rgba(75, 192, 192, 0.6)',
              },
              {
                label: 'Dataset 4',
                data: [15, 25, 35, 45, 55, 65, 75],
                backgroundColor: 'rgba(153, 102, 255, 0.6)',
              },
              {
                label: 'Dataset 5',
                data: [60, 70, 80, 90, 100, 110, 120],
                backgroundColor: 'rgba(255, 159, 64, 0.6)',
              },
              {
                label: 'Dataset 6',
                data: [25, 35, 45, 55, 65, 75, 85],
                backgroundColor: 'rgba(255, 206, 86, 0.6)',
              }
            ]
          },
          chartOptions: {
            responsive: true,
            maintainAspectRatio: false,
            scales: {
              yAxes: [
                {
                  ticks: {
                    beginAtZero: true, // All bars will start at zero
                  },
                  scaleLabel: {
                    display: false, // No label for the Y-axis
                  }
                }
              ],
              xAxes: [
                {
                  stacked: false // Ensure bars are not stacked (optional)
                }
              ]
            },
            legend: {
              display: true // Show the legend for datasets
            }
          }
        }
      },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.graphIcon{
  font-size: 36px;
  stroke-width: 1px;
  margin-left: auto;
  margin-right: auto;
  display: block;
  margin-bottom: .5rem;
}
</style>
